import React from 'react';

function EmptyArticle() {
  return (
    <section className="section section-lg bg-default">
      <div className="container">
        <article className="blog-layout">
          <div className="blog-layout-main">
            <div className="blog-layout-main-item">
              <article className="post-corporate">
                <h3 className="post-corporate-title">In progress... check back later!</h3>
                <p></p>
              </article>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}

export default EmptyArticle;