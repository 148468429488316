import React from 'react';

function Contact() {
    return (
        <section className="section section-lg section-decoration-1 section-decoration-1-figure bg-gray-100" id="section-contact" data-type="anchor">
            {/* eslint-disable-next-line */}
            <div className="container">
                <h3 className="text-center">Contact us</h3>
                <p className="text-center ">Get in touch with us, and we will get you behind the wheel of your own Arion S2</p>
                <div className="row row-50">
                    <div className="col-sm-6 col-lg-4  text-center">
                        <address className="box-2">
                            <p className="heading-4 box-1-title">Asger <br /><small>Commercial</small></p>
                            <p className="box-1-address">Horsens, DK</p>
                            {/* eslint-disable-next-line */}
                            <p className="box-1-tel heading-5"><a href="tel:#+45 51 59 90 00">+45 51 59 90 00</a></p>
                        </address>
                    </div>
                    <div className="col-sm-6 col-lg-4  text-center">
                        <address className="box-2">
                            <p className="heading-4 box-1-title">Christian <br /><small>Technical</small></p>
                            <p className="box-1-address">Horsens, DK</p>
                            {/* eslint-disable-next-line */}
                            <p className="box-1-tel heading-5"><a href="tel:#+45 60 61 63 78">+45 60 61 63 78</a></p>
                        </address>
                    </div>
                    <div className="col-sm-6 col-lg-4  text-center">
                        <address className="box-2">
                            <p className="heading-4 box-1-title">General e-mail <br /><small>Miscellaneous</small></p>
                            <p className="box-1-address">Web</p>
                            {/* eslint-disable-next-line */}
                            <p className="box-1-tel heading-5"><a href="mailto:info@arionracing.dk">info@arionracing.dk</a></p>
                        </address>
                    </div>
                </div>
            </div><img className="section-decoration-1-image" src="images/world-map-728x385.png" alt="" width="728" height="385" />
        </section>

    )
}

export default Contact;