import React from 'react';

function TechnicalArticle({headline, children}) {
  return (
    <section className="section section-lg bg-default">
      <div className="container">
        <article className="blog-layout">
          <div className="blog-layout-main">
            <div className="blog-layout-main-item">
              <article className="post-corporate">
                <h3 className="post-corporate-title">{headline}</h3>
                {children}
              </article>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}

export default TechnicalArticle;