import React from 'react';

function Footer() {
    return (
        <footer className="section footer-modern bg-gray-3 context-dark">
            <div className="footer-modern-aside">
                <div className="container">
                    <div className="row row-30 align-items-center justify-content-between">
                        <div className="col-lg-2 col-xl-auto">
                            <a className="brand" href="/">
                                <img className="brand-logo-dark" src="images/logo-default-268x50.png" alt="" width="134" height="25" />
                                <img className="brand-logo-light" src="images/logo-inverse-250x46.png" alt="" width="125" height="23" />
                            </a>
                        </div>
                        <div className="col-lg-5 col-xl-auto">
                            <p className="rights">
                                <span>&copy;&nbsp; </span>
                                <span className="copyright-year"></span>
                                <span>&nbsp;</span>
                                <span>ArionRacing Aps</span>
                            </p>
                        </div>
                        <div className="col-lg-3 text-xl-right">
                            <div className="footer-modern-list">
                                <div className="group group-sm">
                                    <a className="link-1 link-1-social icon mdi mdi-facebook" target="_blank" href="https://www.facebook.com/groups/547039585628336"></a>
                                    <a className="link-1 link-1-social icon mdi mdi-youtube-play" href="#"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;