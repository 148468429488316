import React, { createContext, useState } from "react";
import PageSub from '../components/PageMain';
import PageMain from '../components/PageMain';

export const GlobalContext = createContext(null);

const GlobalProvider = ({ children }) => {
    const [state, setState] = useState({
        pageComponent: <PageMain>{children}</PageMain>,
        isFrontpage: true,
        selectedMenu: "home"
    });

    return <Provider value={[state, setState]}>{children}</Provider>;
};

const { Provider } = GlobalContext;

GlobalProvider.context = GlobalContext;

export default GlobalProvider;