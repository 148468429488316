import React from 'react';
import TechnicalItem from './TechnicalItem';
import TechnicalArticle, { TopicType } from './TechnicalArticle';
import SingleImage from './SingleImage';


function Technical() {
    return (
        <section className="section section-lg bg-default" id="section-technical" data-type="anchor">
            {/* eslint-disable-next-line */}
            <div className="container">
                <div className="text-center">
                    <h2 className="wow fadeIn">Technical articles</h2>
                </div>
                <div className="row row-30 mt-lg-60">
                    <TechnicalItem key="_1" imgsrc={"suspension.jpg"} headline={"Suspension and breaking"} tagline={"Multipoint, configurable suspension and breaking systems"}>
                        <TechnicalArticle headline={"Suspension and breaking - All adjustable"}>
                            <div className="row zrow-10 zblock-3">
                                <div className="col-sm-6">
                                    <ul className="list-marked mt-xl-40">
                                        <li>Leightweight rocker suspension</li>
                                        <li>Leightweight inboard rocker suspension</li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <ul className="list-marked">
                                        <li>Double adjustable alloy body shock absorbers</li>
                                        <li>Billet rear uprights</li>
                                    </ul>
                                </div>
                                <div style={{ marginTop: "10px" }} className="col-sm-6">
                                    <ul className="list-marked">
                                        <li>Antirollbar</li>
                                    </ul>
                                </div>
                            </div>

                            <p></p>
                            <div className="post-corporate-gallery" data-lightgallery="group">
                                {/*} width="768" height="396" */}
                                <a className="post-corporate-thumbnail post-corporate-thumbnail-1" href="img/technical/frontsuspension.jpg" data-lightgallery="item">
                                    <img className="post-corporate-thumbnail-image" src="img/technical/frontsuspension.jpg" alt="" width="768" height="396" />
                                </a>

                                <a className="post-corporate-thumbnail post-corporate-thumbnail-1" href="img/technical/rearsuspension.jpg" data-lightgallery="item">
                                    <img className="post-corporate-thumbnail-image" src="img/technical/rearsuspension.jpg" alt="" width="768" height="396" />
                                </a>
                                {/* w: 758
                                <a className="post-corporate-thumbnail post-corporate-thumbnail-2" href="img/technical/frontsuspension.jpg" data-lightgallery="item">
                                <img className="post-corporate-thumbnail-image" src="img/technical/frontsuspension.jpg" alt="" width="278" height="331"/>
                                </a>
                                <a className="post-corporate-thumbnail post-corporate-thumbnail-3" href="img/technical/rearsuspension.jpg" data-lightgallery="item">
                                <img className="post-corporate-thumbnail-image" src="img/technical/rearsuspension.jpg" alt="" width="480" height="331"/>
                                </a>
                                */}
                            </div>
                            <p>
                                <b>Lightweight suspension - the foundation for perfect grip.</b><br />
                                Despite using lightweight suspension components – experience has
                                shown exceptional durability.<br />
                                <br />
                                Lightweight suspension components, combined with the optimal geometry is crucial,
                                not only for the handling of the car, but also for the breaking.<br />
                                <br />
                                Besides the great grip entering, turning and exiting the corners the Arion S2 often gains positions
                                during breaking.<br />
                                <br />
                                <b>Keep cool.</b><br />
                                The design of the air intakes at the front secures a high pressure air inflow. Combined with the vent
                                ducks at the side pots, the aerodynamic of the car is generating a negative drag, sucking out hot air
                                from engine cooling as well as break disc, thereby secures a constant flow of “fresh” on air</p>
                        </TechnicalArticle>
                    </TechnicalItem>

                    <TechnicalItem key="_2" imgsrc={"cooling.jpg"} headline={"Cooling"} tagline={"And a look at the hybrid water and oil cooling solution"}>
                        <TechnicalArticle headline={"Cooling"}>
                            <p>Essential for constant performance and durability in a race car is cooling.</p>

                            <p>One of the best things to do when hybriding is to try and avoid the temptation to think of it as a superbike engine - it is being
                            used out of application and therefore many of its operating characteristics will be very different compared to how it performs in the bike.</p>

                            <p>We have taken the engine out of a frame where air flows all around the engine, where throttle openings are generally far more
                            judicious and increased the load from 200 kg till app 500 KG and  bolted it into a box. A box with greater grip, more stability
                            and where use of wide open throttle is virtually every gear change - its brilliant, its cheap bang for buck but it is going
                                to run hot as hell unless we do something to address its change of environment.</p>

                            <p>First up water cooling - when the engine starts to run too hot we lose power and efficiency, we subject components to prolonged
                            exposure to temperatures beyond their design parameters so lets start our build with a good quality radiator that's up to the job.</p>

                            <p><i>Picture of a 2 ROW DOUBLE PASS ALLOY RADIATOR for water cooling.</i></p>
                            <SingleImage src={"technical/cooling1.jpg"} />

                            <p>It is hugely annoying to be at a hot race or track day and be continually fighting elevated temperatures, ideally these engines
                            want to be running maxed out at around 82-85 degrees</p>

                            <p>The onboard Standard Honda pump will be challenged - it is driven from the back of the clutch and so its output is rev related.
                            We have also increased the volume and head of water for it to push and we are going to be using full throttle a lot.</p>

                            <p>The ram intake for Arion S2 secures a constant high pressure air flow going into the two coolers securing perfect cooling during race.</p>

                            <p><i>Picture of ram intake:</i></p>
                            <SingleImage src={"technical/cooling2.jpg"} />

                            <p>A realistic scenario - we have just spent 20 minutes giving the car some real pasty and we come in to the pits, at tick over we can
                            expect our very hot oil to be running at 10 - 12 PSI - not brilliant so we need to switch off the engine. Now our super-hot coolant is not
                            being circulated and so it sits and boils in the cylinder head leading to premature gasket failures and a lot of components subjected to
                            heat soak.</p>

                            <p>We remove the stock pump and blank it with one of our billet block off plates, now we add a good size electric coolant pump and wire
                            it direct to the ignition switch (fitting a switch that needs turning on is a bad idea - you will forget at some point to turn it on and - pop)</p>
                            <p>The Electric coolant pump is not mounted into the cassis, but instead hanging “loos” in a silicon hose in order prevent damaging shocks
                            coming from the chassis.</p>

                            <p>Now we remove the thermostat, cut out the centre and reinstall the outer stat ring compete with seal.</p>

                            <p><i>Picture cooling pump 2 ROW DOUBLE PASS ALLOY RADIATOR</i></p>
                            <SingleImage src={"technical/cooling3.jpg"} />

                            <p>Now when you come in you can turn off the ignition to kill the engine and switch back on so that the water pump is running, the
                                an can now be employed to bring temps down and you can monitor the cool down period, at 50 degrees we shut it down - job done.</p>

                            <p>When it comes to plumbing the system we use as much alloy pipe work as possible and limit the use of silicon to joining these runs,
                            the thinking is straight forward - all of the alloy works as its own radiator as coolant travels down it dissipating heat, we also
                            reduce the amount of clips and joints - all of which represent possible failure paths.</p>
                            <SingleImage src={"technical/cooling4.jpg"} />

                            <p>We use a 22 psi header tank cap - standard 15 psi will leak like a sieve when the heat and pressure build.
                            We remove the feeds to the onboard oil cooler as it makes no sense to pass water around the onboard oil cooler when we have an external oil
                            cooler fitted.</p>

                            <p><i>Picture oil cooler 25 ROW HEAVY DUTY OIL COOLER</i></p>

                        </TechnicalArticle>
                    </TechnicalItem>

                    <TechnicalItem imgsrc={"fueling.jpg"} headline={"Fueling"} tagline={"A look at the eight point digital fuel injection"}>
                        <TechnicalArticle headline={"Fueling"}>
                            <p>The Honda 08-16 CBR uses eight point digital fuel injection. The stock ecu comes with a fixed fuel map designed to deal
                            with stock exhaust and induction, it is fed by an intelligent fuel pump that will maintain correct fuel pressure at any
                                 given throttle application and has no need for a return to tank or rail mounted regulator.</p>

                            <p>Superbike engines with their high revving nature, reasonably small capacity and high compression ratios are very susceptible
                            to changes in air fuel ratio brought about by small changes to pipe and induction systems. The mapping regime as standard also
                            fails to take into account far greater use of wide open throttle openings found in car applications.<br />
                            Whilst stoichiometric balance is 14.7 we target 13.7 for best power.</p>

                            <p>Outwardly the system may appear complicated but broken down into component form it is easy to see the simplicity of the set up
                            and the role of the individual sensors.</p>

                            <p>Throttle position sensor - communicates the throttle opening to the ecu which forms one piece of the information required to
                            supply fuel and advance at the correct target.</p>

                            <p>Air intake temperature - as it says lets the ecu know the ongoing temperature of the air and make small adjustments to fuelling
                            to compensate.</p>

                            <p>Manifold absolute pressure - reads and transmits intake pressure direct from port before inlet valve - this information
                            combined with TPS helps fill in the gaps for the ecu - for example increasing vacuum with TPS at half throttle will suggest
                            we are at half throttle and opening, decreasing vacuum and TPS at half throttle suggests we are throttling off.</p>

                            <p>Water Temp sender - ignoring the feed for the clocks transmits the block and head temp allowing fine tuning of fuelling -
                            as the temps rise more fuel is added in a control response.</p>

                            <p>Knock sensor - quite simply listens for frequency associated with detonation allowing the use of relatively high compression
                            on a range of fuel qualities and temperatures - in other words Honda have in general one base engine for all world markets - ECU
                            response to knock is to pull out advance.</p>

                            <p>Cam position - provides reference point for times injection of fuel</p>

                            <p>Crank Position - provides reference for timing of spark and engine speed and is also used to initiate fuel pump relay operation
                            when motor is cranked</p>

                            <p>Speed sensor - obviously outputs to clocks but also ecu and will be needed to ensure correct full power running and to avoid
                            triggering limp home protocol</p>

                            <p>Right let's look at the hardware required for simple effective operation
                            We have seen a number of different installs most typical of which seems to be.</p>

                            <p>Baffled Tank - low pressure filter - low pressure slave pump - swirl pot - high pressure pump - high pressure filter - pressure
                            regulator - return to tank - feed to injector rails.</p>

                            <p>Wow - how complex not to mention expensive and loaded with potential fail paths and all those components add packaging and
                            weight penalties.</p>

                            <p>We choose a simpler route</p>

                            <p>Tank - Billet CNC alloy swirl pot that accepts stock self regulating pump welded onto tank - stock pump - fuel rail</p>
                            <SingleImage src={"technical/fueling1.jpg"} />

                            <p>Ok let's look at the individual components and design</p>
                            <SingleImage src={"technical/fueling2.jpg"} />

                            <p>Fuel tank - as a rule of thumb wide rectangular tanks suck (air usually  🙂)</p>

                            <p>We are cornering hard and flat and fuel injection has no float bowl to sustain us through surge corners - the truth is
                            many earlier carbed indtalls suffered surge you just didn't know it. At AB we build the tallest tank possible and shape
                            the bottom like a hopper, we then weld our billet swirl pot on the bottom. Using this design we can run to empty without
                            any baffles or tank foam free from surge - this is very important as we all know the effect of lifting throttle mid corner
                            when we are totally committed and that's what will happen with injection when fuel pressure drops.</p>

                            <p>Billet swirl pot - has been designed to accept the CBR1000rr standard fuel pump and fittings - it incorporates blind threads
                            for the pump and the original rubber seal. Please note we issue the earlier 2004 - 6 steel pumps preferring them to the
                            later plastic items.</p>

                            <p>Fuel pump - stock Honda - self regulating with integral filter sac - dry break on banjo feed meaning you can remove a
                            full tank with only minor spillage from line fuel.</p>

                            <SingleImage src={"technical/fueling3.jpg"} />

                            <p>Hose up to t piece and split to top and bottom rails.</p>

                            <p>Job done - cheap and effective - we can build the tank for you to any design or send you a billet adaptor for home fitment.
                            Right fuel control - as stated we are going to change the induction and exhaust and use the engine in a very different
                            way - you MUST address this and we always reach for the power commander range of products - relatively inexpensive, very
                            good control on fuelling, widely recognised by most rolling roads, reliable, huge range of add ins to expand the system
                            and excellent product support, it is also plug and play install.</p>

                            <p>Once fitted your next stop has been a reputable rolling road the highly recommend Daytuner Performance who has mapped
                            all of our vehicles over the years and with which we have had huge on track success.</p>

                        </TechnicalArticle>
                    </TechnicalItem>

                    <TechnicalItem imgsrc={"lubrication.jpg"} headline={"Lubrication"} tagline={"Engine lubrication system"}>
                        <TechnicalArticle headline={"Lubrication"}>
                            <p><b>Honda 08-16 CBR1000rr engine lubrication system</b></p>

                            <p>Due to the lack of lean angle found in a bike engine car there is nothing to counteract the forces set up in cornering and it
                            is very easy to experience surge.</p>

                            <p>Surge can occur in heavy cornering, braking and accelerating and it is not confined to track use. Roundabouts are a likely place
                            to encounter surge issues during spirited use.</p>

                            <p>Bearing in mind the crank is effectively supported on a high pressure bead of oil controlled by very soft metal shells, it is easy
                            to see how a momentary lack of oil pressure can lead to damage and deformation of a big end or main bearing shell.</p>

                            <p>Once damaged the clock is ticking and it is only a matter of time before failure occurs.</p>

                            <p>The good news is the the CBR engine can easily be controlled using a simple baffled billet sump.</p>
                            
                            <p>In 2008 having developed sump systems for many other motors we turned our attention to the New generation blade.</p>
                            <SingleImage src={"technical/lubrication.jpg"} />

                        <p>We have seen many attempts at wet sump systems for this engine over the years, over complex, flap valves, swinging gates and flimsy
                        steel internal baffles have all led to expensive failures. Drawing on past experience and in the knowledge that simple is usually
                        best we built our now familiar 5 chamber, 2 piece billet sump. Supplied with its own laser cut stainless baffle and modified
                        replacement pick up, we have supplied this unit to hundreds of customers and indeed many rival manufacturers over the years.
                        We have used it on road cars, race cars, off road racers and run it in slicks and wings with zero issues - just keep an eye out for
                        some rather nasty inferior copies that are out there.</p>

                        <p>This CBR uses a very different oil system to earlier models that does not have a conventional oil pressure relief valve with Honda
                        opting for a supercharged recirculating set up. Whilst very efficient and less likely to cause foaming that can occur with
                        conventional relief valves, it does have one slight issue. When completely drained and refilled, the engine can be an utter
                        nightmare to prime and create oil pressure. A combination of slow cranking speed and a tendency to air lock, the engine can be
                        turned over for long periods of time and even run with zero oil pressure. To avoid this issue I have outlined a simple process
                        to follow below</p>

                        <p>Fill engine up to the maximum mark on the dipstick</p>

                        <p>For reference the oil level on these engines is always taken cold and with the dipstick resting on its threads not screwed in.
                        When satisfied that the level is at max remove the spark plugs, this will avoid placing compression pressure on relatively dry
                        shells whilst cranking for oil pressure</p>

                        <p>Now use an airline to introduce a little pressure to the cam cover breather whilst cranking over - this will clear any airlock
                        almost immediately and very quickly oil pressure will be achieved.</p>

                        <p>Do not expect to see big psi numbers whilst cranking - 2 or 3 psi will indicate a prime.</p>

                        <p>Now let the oil level settle whilst refitting the spark plugs and then recheck the oil level. Top it up to the max mark and then
                        add oil until it sits above the max mark where the dipstick goes from flat back to round.</p>

                        <p>This will be approximately a 259ml overfill that will keep a prime above baffle at all times whilst not being high enough to dip
                        the clutch or crank causing aeration and drag.</p>

                        <p>I mentioned oil pressure - you must run oil pressure and oil temp gauges on these engines and it is frankly mad not too, if I had
                        nothing else I would have these two pieces of information.</p>

                        <p>Oil pressure is easily hooked up by removing the standard switch and replacing it with a sender unit but the sender needs to be on
                        a braided line as vibration tends to kill them very quickly when screwed directly into blocks.</p>

                        <p>Oil temp is easy too - simply remove the 30mm oil cooler bolt, tap it for the sensor you are using and blow out any dwarf and refit.</p>

                        <p>Ok time to look at oil cooling - the stock onboard cooler is not man enough for anything but the shortest of runs, we have effectively
                        placed the engine in a box devoid of most of the airflow encountered on the bike, furthermore we then use wide open throttle much more
                        than in the bike. These Hondas rev to 13700 rpm and are a relatively high compression engine. They generate a lot of heat.</p>

                        <p>We prefer to let the water cool the engine and use an air/oil cooler to deal with oil temp.
                        To this end we simply leave the original cooler in.</p>
                        
                        <p>Place but disconnect its coolant feeds. We then fit our billet sandwich plate between the oil filter and the block and feed an oil
                        cooler fitted up front.</p>

                        <p>We have never found the need to run thermostatic control sandwich plates and prefer to keep the install simple.
                        AN 10 hoses for feed and return are perfect and a 235 x 25 row cooler will be bang in although we have dropped this to 13 row in
                        some of the tighter 7 style nose cones.</p>

                        <p>Another word of caution - do not use lightweight air oil cooler ms, they are simply not up to the vibration and harsh environment
                        and we have seen many expensive failures</p>

                        <p>Use a heavy duty unit from a respected manufacturer, we stock and supply Mocal and Setrab units.
                        We always plumb using aeroquip hoses in our installations for maximum safety.</p>

                        <p>There we have it and remember all our products come with free advice so if you need any help you only have to ask...</p>
                        </TechnicalArticle>
                    </TechnicalItem>
                </div>
            </div>
        </section>
    )
}

export default Technical;