import React from 'react';

function CTA() {
    return (
        <section className="parallax-container section-lg context-dark text-center" data-parallax-img="/img/successfulcar.jpg">
            <div class="ar-backdrop"></div>
            <div className="parallax-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-10 col-md-8 col-lg-7">
                            <h2 className="wow fadeIn" data-wow-delay=".025s"><span className="big">Arion S2 UK's most<br />successful car in its class</span></h2>
                            <h5 className="wow fadeIn" data-wow-delay=".05s">
                                Only high performing Formula cars can match<br /> 
                                the capabilities of the Arion S2</h5>
                            <a className="button button-primary" href="mailto:info@arionracing.dk?subject=I'm%20interested%20in%20the%20Arion%20S2&body=Hi%20Arion%20Team%20Scandinavia%0D%0A%0D%0AName:%20______________%0D%0AToday%20I%20drive:%20______________%0D%0A%0D%0APlease%20reach%20out%20to%20me%20using%20these%20contact%20details:%0D%0A%20______________%0D%0A">I want to try this car!</a>
                        </div>
                    </div>
                    <h3>Results from 41 races in the UK</h3>
                    <div className="row row-50">
                        <div className="col-6 col-sm-3">
                            <article className="counter-classic">
                                <div className="counter-classic-main"><span>39</span></div>
                                <h5 className="counter-classic-title">Pole positions</h5>
                            </article>
                        </div>
                        <div className="col-6 col-sm-3">
                            <article className="counter-classic">
                                <div className="counter-classic-main"><span>39</span></div>
                                <h5 className="counter-classic-title">first-places</h5>
                            </article>
                        </div>
                        <div className="col-6 col-sm-3">
                            <article className="counter-classic">
                                <div className="counter-classic-main"><span>40</span></div>
                                <h5 className="counter-classic-title">fastest laptime</h5>
                            </article>
                        </div>
                        <div className="col-6 col-sm-3">
                            <article className="counter-classic">
                                <div className="counter-classic-main"><span>37</span><span>+</span></div>
                                <h5 className="counter-classic-title">new lap record</h5>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CTA;