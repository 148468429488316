import React, { useContext } from 'react';
import { GlobalContext } from '../provider/GlobalStateProvider'
import PageSub from './PageSub';
import PageMain from './PageMain';
import Gallery from './Gallery';

function HeaderMenu({ selectedMenu, speakerClassName, toggleAudio }) {

    const [state, setState] = useContext(GlobalContext);

    const menuItems = [
        { title: "Home", name: "home" },
        { title: "Gallery", name: "gallery" },
        // { title: "Gallery", name: "gallery", content: <Gallery/>, headline : "Pictures and video", tagline : "The Arion S2 in action and in the showroom" },
        { title: "Technical", name: "technical" },
        { title: "Press", name: "press" },
        { title: "Contact us", name: "contact" }
    ]

    let menu = [];
    for (let i = 0; i < menuItems.length; i++) {
        let current = menuItems[i];
        menu.push(
            <li className={state.selectedMenu == current.name ? "rd-nav-item active" : "rd-nav-item"} id={current.name + "_menu"} key={"key_" + current.name}>
                <a className="rd-nav-link" name={current.name} onClick={(e) => handel(e)} href={"#section-" + current.name}>{current.title}</a>
            </li>
        );
    }

    const handel = (event) => {
        let name = event.currentTarget.name;
        let menuItem = menuItems.find((item) => item.name === name);
        if (menuItem.content) {
            setState({
                pageComponent: <PageSub headline={menuItem.headline} tagline={menuItem.tagline} menu={name}>{menuItem.content}</PageSub>,
                isFrontpage: false,
                selectedMenu: menuItem.name
            })
        }
        else {
            setState({
                pageComponent: <PageMain/>,
                isFrontpage: true,
                selectedMenu: menuItem.name
            })  
        }
    }

    return (
        <ul className="rd-navbar-nav">
            {menu}
            <li className={"rd-nav-item d-none d-xl-inline"} id={"audio_toggle"} key={"key_audio"}>
                <a style={{fontSize : "1.5em" }} className={speakerClassName} name="audio" onClick={toggleAudio} href={"javascript:void(0);"}></a>
            </li>            
        </ul>
    )
}

export default HeaderMenu;