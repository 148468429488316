import React from 'react';

function Specs() {
  return (
    <section class="section section-md bg-gray-100 text-center text-sm-left">
      <div class="container">
        <h2 class="text-center">Arion S2 - Lighter, stronger, faster</h2>
        <h5 class="text-center mb-5">The Arion S2 is a high performance racecar with superiour handling at an affordable price.</h5>
        <ul class="list-group-1 row row-50">
          <li class="col-sm-6 col-lg-3">
            <article class="lg-1-item">
              <div class="icon lg-1-item-icon linearicons-engine"></div>
              <div class="lg-1-item-main">
                <h4 class="lg-1-item-title"><span class="lg-1-item-counter"></span>Engine</h4>
                <p>Propelled forward by the powerplant of Hondas race focused 1000cc RR engine.</p>
                <ul className="list-marked mt-20">
                  <li>185 HK</li>
                  <li>1250 RPM</li>
                </ul>
              </div>
            </article>
          </li>
          <li class="col-sm-6 col-lg-3">
            <article class="lg-1-item">
              <div class="icon lg-1-item-icon linearicons-road"></div>
              <div class="lg-1-item-main">
                <h4 class="lg-1-item-title"><span class="lg-1-item-counter"></span>Handling</h4>
                <p>The advanced suspension geometry combined with the ultra light weight yet durable components and a perfect weight balance gives the race car a unique and easy to control balance.</p>
                <ul className="list-marked mt-20">
                  <li>Leightweight rocker & inboard rocker suspension</li>
                  <li>Double adjustable alloy body shock absorbers</li>
                  <li>Billet rear uprights</li>
                  <li>Anti roll bar</li>
                </ul>
              </div>
            </article>
          </li>
          <li class="col-sm-6 col-lg-3">
            <article class="lg-1-item">
              <div class="icon lg-1-item-icon linearicons-speed-fast"></div>
              <div class="lg-1-item-main">
                <h4 class="lg-1-item-title"><span class="lg-1-item-counter"></span>Speed</h4>
                <p>Cornering and breaking is the hallmark of an Arion racecar, combining this with the low weight and lots of power makes this one of the fastest race cars on short tracks.</p>
                <ul className="list-marked mt-20">
                  <li>0-100 km/h &lt; 4 sec</li>
                  <li>500 KG</li>
                </ul>
              </div>
            </article>
          </li>
          <li class="col-sm-6 col-lg-3">
            <article class="lg-1-item">
              <div class="icon lg-1-item-icon linearicons-wrench"></div>
              <div class="lg-1-item-main">
                <h4 class="lg-1-item-title"><span class="lg-1-item-counter"></span>Maintenance</h4>
                <p>Low maintenance cost! Components selected with no compromises, minimum tyre wear.</p>
                <p>Oil, brake pads, two sets of affordable tyres and a clutch, takes you through an entire race season.</p>
                <ul className="list-marked mt-20">
                  <li>Full onsite support</li>
                  <li>Full factory support</li>
                </ul>
              </div>
            </article>
          </li>
        </ul>
      </div>
    </section>

  )
}

export default Specs;