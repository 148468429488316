import React, { useContext } from 'react';
import HeaderMenu from './HeaderMenu';
import PageMain from './PageMain';
import PageSub from './PageSub';
import { GlobalContext } from '../provider/GlobalStateProvider'

function HeaderMain({ headerimage, selectedMenu }) {

    const [state, setState] = useContext(GlobalContext);

    let muted = !state.audio;
    let speakerClassName = "mdi mdi-volume-off";
    if (!muted) speakerClassName = "mdi mdi-volume-high";

    const toggleAudio = () => {
        setState(previousState => ({
            ...previousState,
            audio: !previousState.audio
        }));
    }

    return (
        <header className="page-header" id="section-home" data-type="anchor">
            <div className="rd-navbar-wrap rd-navbar-wrap-mod-1">
                <nav className="rd-navbar rd-navbar-modern" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
                    data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-fixed"
                    data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static"
                    data-xl-device-layout="rd-navbar-static" data-lg-stick-up-offset="46px" data-xl-stick-up-offset="46px"
                    data-xxl-stick-up-offset="46px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
                    <div className="rd-navbar-main-outer">
                        <div className="rd-navbar-main">
                            <div className="rd-navbar-panel">
                                <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span></span></button>
                                <div className="rd-navbar-brand">
                                    <a className="brand" href="#top"><img className="brand-logo-dark" src="images/logo-default-268x50.png" alt="" width="134" height="25" />
                                        <img className="brand-logo-light" src="images/logo-inverse-250x46.png" alt="" width="125" height="23" />
                                    </a>
                                </div>
                                <div className="rd-navbar-right d-xl-none">
                                    <a style={{ fontSize: "1.5em", lineHeight: "2em" }} className={speakerClassName} name="audio" onClick={toggleAudio} href={"javascript:void(0);"}></a>
                                </div>
                            </div>
                            <div className="rd-navbar-right">
                                <div className="rd-navbar-nav-wrap">
                                    <HeaderMenu selectedMenu={selectedMenu} toggleAudio={toggleAudio} speakerClassName={speakerClassName}></HeaderMenu>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="ar-header-video-overlay"></div>
            <div className="ar-header-video">
                <video playsInline autoPlay={true} muted={muted} loop={true} preload={"true"} width="100%">
                    <source src="./video/bannervideo.mp4?v=6" type="video/mp4" />
                </video>
            </div>
        </header>
    )
}

export default HeaderMain;